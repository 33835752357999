import { React, useEffect } from "react";
import { Box, Typography } from '@mui/material';

export function CorporatePage() {
    useEffect(() => {
        document.title = 'Kurumsal | Kristal Fotonik';

    }, []);
    return (
        <Box>
            <Typography variant="h1" sx={{ textAlign: 'center', wordWrap: 'break-word' }}>
                KURUMSAL
            </Typography>
        </Box>
    );
}