import React from "react";
import { Button } from "@mui/material";
import FacebookIcon from '@mui/icons-material/Facebook';
import InstagramIcon from '@mui/icons-material/Instagram';
import TwitterIcon from '@mui/icons-material/Twitter';
import YouTubeIcon from '@mui/icons-material/YouTube';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import { Mail, Phone, LocationCity } from "@mui/icons-material";
export const SocialButton=({ iconMedia, url}) => {
    let sMediaIcons; // Değişkenlerin blok kapsamında sadece burada erişilebilir olmasını sağlar. Bir değişkenin erişimi sınırlamak ya da geçici olarak tanımlamak için değişkenleri "let" ile oluşturmak uygundur.
    switch (iconMedia) {
    
        case "phone":
            sMediaIcons = <Phone />;
            break;
        case "mail":
            sMediaIcons = <Mail />;
            break;
        case "location":
            sMediaIcons=<LocationCity />;
            break;
        case "facebook":
            sMediaIcons = <FacebookIcon />;
            break;
        case "instagram":
            sMediaIcons = <InstagramIcon />;
            break;
        case "twitter":
            sMediaIcons = <TwitterIcon />;
            break;
        case "youtube":
            sMediaIcons = <YouTubeIcon />;
            break;
        case "linkedin":
            sMediaIcons = <LinkedInIcon />;
            break;
        default:
            sMediaIcons = null;
            break;
    }
    return (
        <Button href={url} target="_blank" rel="noopener noreferrer" sx={{ m: 1, color: "white" }}>
            {sMediaIcons}
        </Button>
    );
}