import React from 'react';
import { FooterSocialMedia } from './FooterSocialMedia';
import logoImage from '../../../assets/logo.png'; // logo.png dosyasının doğru yolunu buraya yazın

export const Footer = () => {
  return (
    <div style={{ overflow: 'hidden' }}>
      <footer style={styleForFooter}>
        <div style={styleForColumn}>
          <div style={logoWrapperStyle}>
            <img src={logoImage} alt="Logo" style={logoStyle} />
          </div>
          <h4 style={styleForDescription}>
            Kristal Fotonik Görüntüleme Teknolojileri Inc. <br />
            <span style={styleForSlogan}>Where Vision Meets Innovation.</span>
          </h4>
          <div style={styleForSocialMedia}>
            <FooterSocialMedia iconMedia="linkedin" url="https://www.linkedin.com/company/kristal-fotonik/" />
            <FooterSocialMedia iconMedia="instagram" />
            <FooterSocialMedia iconMedia="twitter" />
            <FooterSocialMedia iconMedia="youtube" />
          </div>
        </div>

        <div style={styleForColumn}>
          <h2 style={styleForContactTitle}><u>Contact Us</u></h2>
          <div style={styleForContactRow}>
            <FooterSocialMedia iconMedia="phone" url="https://www.kristalfotonik.com" />+(90) 555 223 01 62
          </div>

          <div style={styleForContactRow}>
            <FooterSocialMedia iconMedia="location" url={mapUrl} />İvedik OSB 2224. Avenue No: 1/110 Yenimahalle / ANKARA
          </div>
        </div>
      </footer>

      <div style={styleForSeparator}></div>

      <p style={styleForCopyright}>
        <strong>Copyright &copy; {new Date().getFullYear()} Kristal Fotonik | All Rights Reserved.</strong>
      </p>
    </div>
  );
};

const mapUrl = 'https://maps.app.goo.gl/EEnPQQhkQ28eBpmf8';

// Stil ayarları
const logoWrapperStyle = {
  display: 'flex',
  alignItems: 'flex-start', // Sola hizala
  margin: '0', // Kenar boşluklarını sıfırla
  padding: '0', // İç boşlukları sıfırla
};

const logoStyle = {
  display: 'block', // Varsayılan inline stili yerine block kullan
  margin: '0', // Her türlü dış boşluğu sıfırla
  padding: '0', // Her türlü iç boşluğu sıfırla
  width: '200px', // Genişliği 200px olarak ayarladık, daha küçük yapıldı
  height: 'auto', // Yükseklik otomatik ayarlanacak
};


const styleForFooter = {
  backgroundColor: '#333333',
  color: '#FFFFFF',
  padding: '20px 40px', // Yanlardan boşluğu artır
  display: 'flex',
  justifyContent: 'space-between',
  flexWrap: 'wrap',
};

const styleForColumn = {
  flex: '1 1 300px',
  margin: '0 10px',
  textAlign: 'left',
};

const styleForDescription = {
  fontSize: '15px',
  textAlign: 'justify',
  lineHeight: '1.4',
  margin: '5px 0 10px 0', // Yukarıdan aşağıya boşlukları ayarla
};

const styleForSlogan = {
  fontSize: '18px',
  fontStyle: 'italic',
  fontWeight: 'bold',
  color: '#ffffff',
  margin: '0',
};

const styleForSocialMedia = {
  display: 'flex',
  justifyContent: 'flex-start', // Sol tarafa hizala
  gap: '10px',
  marginTop: '10px', // Üstten biraz boşluk
};

const styleForContactTitle = {
  fontSize: '18px',
  textAlign: 'left',
  marginBottom: '10px',
};

const styleForContactRow = {
  display: 'flex',
  alignItems: 'center',
  marginTop: '5px',
  gap: '10px',
};

const styleForSeparator = {
  height: '2px',
  backgroundColor: '#FFFFFF',
  margin: '0',
};

const styleForCopyright = {
  textAlign: 'center',
  fontSize: '14px',
  margin: '2px 0',
  padding: '15px 0',
};
