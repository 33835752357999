import {React, useEffect} from "react";

import {Box, Typography} from '@mui/material';
export function ReferencesPage(){
    useEffect(()=> {
        document.title = 'Referanslarımız | Megasoft'
    }, []);
    return(
        <Box>
        <Typography variant="h1" sx={{ textAlign: 'center', wordWrap: 'break-word' }}>
           REFERANSLARIMIZ
        </Typography>
    </Box>
    );
}