import React, { useEffect, useState } from "react";
import { motion } from 'framer-motion';

export function CommunicationPage() {
    const [formData, setFormData] = useState({
        name: "",
        email: "",
        message: ""
    });

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value
        });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const response = await fetch("YOUR_BACKEND_OR_EMAIL_SERVICE_URL", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(formData),
            });
            if (response.ok) {
                alert("Mesajınız başarıyla gönderildi.");
                setFormData({ name: "", email: "", message: "" }); // Formu temizle
            } else {
                alert("Bir hata oluştu. Lütfen tekrar deneyin.");
            }
        } catch (error) {
            console.error("Error submitting form:", error);
            alert("Bir hata oluştu. Lütfen tekrar deneyin.");
        }
    };

    useEffect(() => {
        document.title = 'İletişim | Kristal Fotonik';
    }, []);

    return (
        <div style={styles.container}>
            <h1 style={styles.title}>Contact Us</h1>

            {/* Harita (Google Maps Embed) */}
            <div style={styles.mapContainer}>
                <iframe
                    title="Şirket Lokasyonu"
                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3056.5426174814475!2d32.749302776035684!3d39.99632557151026!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x14d34bec9c65fc35%3A0xbcdd4a5c7773c959!2sKristal%20Fotonik!5e0!3m2!1str!2str!4v1728402144038!5m2!1str!2str"
                    width="100%"
                    height="400"
                    style={{ border: 0 }}
                    allowFullScreen=""
                    loading="lazy"
                ></iframe>
            </div>

            {/* İletişim Formu Animasyonu */}
            <motion.form
                onSubmit={handleSubmit}
                style={styles.form}
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.5 }}
            >
                <div style={styles.formGroup}>
                    <label htmlFor="name" style={styles.label}>Name</label>
                    <input
                        type="text"
                        id="name"
                        name="name"
                        value={formData.name}
                        onChange={handleChange}
                        style={styles.input}
                        required
                    />
                </div>
                <div style={styles.formGroup}>
                    <label htmlFor="email" style={styles.label}>E-mail</label>
                    <input
                        type="email"
                        id="email"
                        name="email"
                        value={formData.email}
                        onChange={handleChange}
                        style={styles.input}
                        required
                    />
                </div>
                <div style={styles.formGroup}>
                    <label htmlFor="message" style={styles.label}>Your Messages</label>
                    <textarea
                        id="message"
                        name="message"
                        value={formData.message}
                        onChange={handleChange}
                        style={styles.textarea}
                        required
                    />
                </div>
                <button type="submit" style={styles.button}>Send</button>
            </motion.form>
        </div>
    );
}

const styles = {
    container: {
        padding: '40px 20px',
        maxWidth: '900px',
        margin: '80px auto 0', // Üst kısmına daha fazla boşluk ekledik
        fontFamily: 'Arial, sans-serif',
        color: '#333',
        backgroundColor: '#f8f9fa',
    },
    title: {
        fontSize: '36px',
        fontWeight: 'bold',
        textAlign: 'center',
        color: '#007BFF',
        marginBottom: '30px',
        marginTop: '20px',
    },
    contactInfo: {
        textAlign: 'center',
        fontSize: '18px',
        marginBottom: '30px',
    },
    emailLink: {
        color: '#007BFF',
        textDecoration: 'none',
    },
    mapContainer: {
        margin: '30px 0',
        boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
    },
    form: {
        backgroundColor: '#fff',
        padding: '20px',
        borderRadius: '8px',
        boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
    },
    formGroup: {
        marginBottom: '20px',
    },
    label: {
        display: 'block',
        fontSize: '16px',
        marginBottom: '8px',
        fontWeight: 'bold',
    },
    input: {
        width: '100%',
        padding: '10px',
        fontSize: '16px',
        borderRadius: '4px',
        border: '1px solid #ccc',
    },
    textarea: {
        width: '100%',
        padding: '10px',
        fontSize: '16px',
        borderRadius: '4px',
        border: '1px solid #ccc',
        minHeight: '150px',
    },
    button: {
        width: '100%',
        padding: '12px',
        fontSize: '18px',
        backgroundColor: '#007BFF',
        color: '#fff',
        border: 'none',
        borderRadius: '4px',
        cursor: 'pointer',
    },
    buttonHover: {
        backgroundColor: '#0056b3',
    }
};
