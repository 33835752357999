import React, { useEffect } from "react";
import { Box, Typography, Paper, Container } from '@mui/material';
import { motion } from 'framer-motion';

export function ProjectsPage() {
  useEffect(() => {
    document.title = 'Teknolojimiz | Kristal Fotonik';
  }, []);

  // Animasyon ayarları
  const techVariants = {
    hidden: { opacity: 0, y: 20 },
    visible: { opacity: 1, y: 0 }
  };

  return (
    <Box sx={{ flexGrow: 1, padding: 5, paddingTop: '120px', backgroundColor: '#f5f5f5' }}> {/* paddingTop değerini artırdık */}
      {/* Teknoloji Başlığı */}
      <Typography variant="h2" sx={{
        textAlign: 'center',
        color: '#334766',
        fontWeight: 'bold',
        marginBottom: '40px',
        textTransform: 'uppercase',
        letterSpacing: '1.5px',
      }}>
        Technology
      </Typography>

      <Container maxWidth="lg"> {/* maxWidth değerini 'lg' olarak güncelledik */}
        {/* Teknoloji Açıklaması */}
        <motion.div
          initial="hidden"
          animate="visible"
          variants={techVariants}
          transition={{ duration: 0.5 }}
        >
          <Paper elevation={3} sx={{ padding: 4, borderRadius: 2, marginBottom: 4, backgroundColor: '#ffffff', boxShadow: '0 4px 20px rgba(0, 0, 0, 0.1)' }}>
            <Typography variant="body1" sx={{ mt: 2, color: '#555', lineHeight: '1.6' }}>
              At Kristal Fotonik, we leverage cutting-edge technology to develop advanced imaging systems that meet the demands of various industries. Our commitment to innovation drives us to integrate the latest advancements in imaging technology into our products and solutions.
            </Typography>
          </Paper>

          {/** Teknoloji Kartları */}
          {[
            {
              title: "• Advanced Sensor Technology",
              description: "Our cameras are equipped with high-performance sensors that provide exceptional image quality, even in low-light conditions. These sensors are designed to capture detailed images with high dynamic range, ensuring accurate representation of the captured scene."
            },
            {
              title: "• Artificial Intelligence and Machine Learning",
              description: "We utilize AI and machine learning algorithms to enhance image processing capabilities. Our systems can automatically analyze and interpret images, enabling real-time decision-making and improving operational efficiency across various applications."
            },
            {
              title: "• Robust Imaging Algorithms",
              description: "Our advanced imaging algorithms optimize image quality, enhance clarity, and reduce noise, ensuring that users receive the best possible results in challenging environments."
            },
            {
              title: "• Seamless Integration",
              description: "Our imaging systems are designed for easy integration into existing infrastructures. With flexible interfaces and customizable features, we ensure that our products can be tailored to meet the specific needs of each application."
            },
            {
              title: "• Reliability and Durability",
              description: "All our products are built to withstand the rigors of real-world applications. Our rigorous testing processes ensure that each system performs reliably in the toughest conditions, from extreme temperatures to high humidity and shock."
            },
            {
              title: "• Future-Ready Technology",
              description: "We are continuously investing in research and development to stay ahead of technological advancements. Our commitment to innovation ensures that our clients benefit from the latest and most effective imaging solutions."
            }
          ].map((tech, index) => (
            <Paper key={index} elevation={3} sx={{ padding: 4, borderRadius: 2, marginBottom: 4, backgroundColor: '#ffffff', boxShadow: '0 4px 20px rgba(0, 0, 0, 0.1)' }}>
              <Typography variant="h5" sx={{ fontWeight: 'bold', color: '#333' }}>
                {tech.title}
              </Typography>
              <Typography variant="body1" sx={{ mt: 2, color: '#555' }}>
                {tech.description}
              </Typography>
            </Paper>
          ))}
        </motion.div>
      </Container>
    </Box>
  );
}
