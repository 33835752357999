import React, { useEffect } from 'react';
import { Box, Typography, Container } from '@mui/material';
import { keyframes } from '@mui/system';

// Işık tutma animasyonu için keyframes
const lightAnimation = keyframes`
  0% {
    opacity: 0;
    transform: translateY(-20px);
  }
  50% {
    opacity: 1;
    transform: translateY(0);
  }
  100% {
    opacity: 0.8;
    transform: translateY(-5px);
  }
`;

export function HomePage() {
  useEffect(() => {
    document.title = 'Anasayfa | Kristal Fotonik'; // Dinamik sayfa başlığı
  }, []);

  return (
    <Box
      sx={{
        minHeight: '100vh',
        backgroundColor: '#f5f5f5',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        padding: '40px 20px',
        textAlign: 'center',
      }}
    >
      <Container maxWidth="md">
        {/* Vurgulanan Slogan */}
        <Typography
          variant="h2"
          sx={{
            color: '#334766',
            marginBottom: '20px',
            fontWeight: 'bold',
            animation: `${lightAnimation} 1.5s ease-in-out`, // Animasyonu ekle
            fontSize: { xs: '1.8rem', md: '2.5rem' }, // Mobil ve masaüstü için farklı boyutlar
            textTransform: 'uppercase', // Slogan için büyük harf
            letterSpacing: '0.1rem', // Harfler arası boşluk
            textAlign: 'left', // Yazı hizalamasını sola ayarla
          }}
        >
          Where Vision Meets Innovation
        </Typography>

        <Typography
          variant="h4"
          sx={{
            color: '#333',
            marginBottom: '20px',
            fontWeight: 'bold',
            animation: `${lightAnimation} 1.5s ease-in-out`, // Animasyonu ekle
            textAlign: 'left', // Yazı hizalamasını sola ayarla
          }}
        >
          Empowering Industries with Advanced Imaging Solutions
        </Typography>

        {/* Şirket Hakkında Bölümü */}
        <Typography
          variant="body1"
          sx={{
            color: '#555',
            lineHeight: '1.8',
            marginBottom: '20px',
            animation: `${lightAnimation} 1.5s ease-in-out`, // Animasyonu ekle
            textAlign: 'left', // Yazı hizalamasını sola ayarla
          }}
        >
          Kristal Fotonik specializes in delivering cutting-edge imaging technologies that enhance clarity and performance across industrial sectors.
        </Typography>

        <Typography
          variant="body1"
          sx={{
            color: '#555',
            lineHeight: '1.8',
            marginBottom: '20px',
            animation: `${lightAnimation} 1.5s ease-in-out`, // Animasyonu ekle
            textAlign: 'left', // Yazı hizalamasını sola ayarla
          }}
        >
          Kristal Fotonik is a recently formed company. The company was established in August 2024 and it is based in a technology hub that supports innovation and research-driven companies in the fields of science, technology, and engineering in Ankara, Turkey.
        </Typography>

        <Typography
          variant="body1"
          sx={{
            color: '#555',
            lineHeight: '1.8',
            marginBottom: '20px',
            animation: `${lightAnimation} 1.5s ease-in-out`, // Animasyonu ekle
            textAlign: 'left', // Yazı hizalamasını sola ayarla
          }}
        >
          Our AI-powered cameras and advanced image processing algorithms provide reliable, high-quality results, even in the most challenging environments. Committed to innovation, Kristal Fotonik strives to offer solutions that push the boundaries of what’s possible in imaging technology.
        </Typography>

        <Typography
          variant="body1"
          sx={{
            color: '#555',
            lineHeight: '1.8',
            marginBottom: '20px',
            animation: `${lightAnimation} 1.5s ease-in-out`, // Animasyonu ekle
            textAlign: 'left', // Yazı hizalamasını sola ayarla
          }}
        >
          Kristal Fotonik aims to simplify and improve lives through innovative image processing technologies, providing solutions that enhance efficiency and create a positive impact on industries worldwide, with a focus on designing robust industrial-grade camera modules.
        </Typography>

        <Typography
          variant="body1"
          sx={{
            color: '#555',
            lineHeight: '1.8',
            marginBottom: '20px',
            animation: `${lightAnimation} 1.5s ease-in-out`, // Animasyonu ekle
            fontWeight: 'bold',
            textAlign: 'left', // Yazı hizalamasını sola ayarla
          }}
        >
          Discover how Kristal Fotonik can revolutionize your imaging needs.
        </Typography>
      </Container>
    </Box>
  );
}
