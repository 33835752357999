import React, { useState } from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import Container from '@mui/material/Container';
import Button from '@mui/material/Button';
import MenuItem from '@mui/material/MenuItem';
import { useNavigate } from 'react-router-dom';
import { Logo } from '../../Elements/Logo/Logo';

const pages = [
  { id: 1, path: 'home', text: 'Home' },
  { id: 2, path: 'ourServices', text: 'Products & Solutions' },
  { id: 3, path: 'projects', text: 'Technology' },
  { id: 4, path: 'communication', text: 'Contact' }
];

export default function AppBarView() {
  const navigate = useNavigate();
  const [toggleNavMenu, settoggleNavMenu] = useState(null);

  const ToggleMenu = (e) => {
    settoggleNavMenu(toggleNavMenu ? null : e.currentTarget);
  };

  const OpenPage = (path) => {
    navigate(path);
    settoggleNavMenu(null); // Menü kapatılır
  };

  return (
    <AppBar position="fixed" style={{ backgroundColor: '#333', boxShadow: '0 4px 10px rgba(0, 0, 0, 0.2)' }}>
      <Container maxWidth="xl">
        <Toolbar sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', height: '80px' }}>

          {/* Logo ve İsim */}
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              flexGrow: 1,
              marginLeft: -2,
              maxWidth: '200px', // Logonun genişliğini sınırladık
              maxHeight: '100px'  // Logonun yüksekliğini sınırladık
            }}
            onClick={() => OpenPage('home')}
            style={{ cursor: 'pointer' }}
          >
            <Logo style={{ width: '100%', height: 'auto' }} /> {/* Responsive logo ayarları */}
          </Box>

          {/* Mobil Menü İkonu */}
          <Box sx={{ display: { xs: 'flex', sm: 'flex', md: 'none' } }}>
            <IconButton
              sx={{ color: '#fff' }}
              size="small"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={ToggleMenu}
            >
              <MenuIcon />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={toggleNavMenu}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}
              open={Boolean(toggleNavMenu)}
              onClose={ToggleMenu}
              sx={{
                display: { xs: 'flex', md: 'none' },
                color: 'white'
              }}
            >
              {pages.map((page) => (
                <MenuItem key={page.id} onClick={() => OpenPage(page.path)}>
                  <Typography textAlign="center">{page.text}</Typography>
                </MenuItem>
              ))}
            </Menu>
          </Box>

          {/* Menü - Desktop görünüm için */}
          <Box sx={{ display: { xs: 'none', md: 'flex' }, flexGrow: 1, justifyContent: 'flex-end' }}>
            {pages.map((page) => (
              <Button
                key={page.id}
                onClick={() => OpenPage(page.path)}
                sx={{
                  my: 2,
                  color: '#fff',
                  display: 'block',
                  fontFamily: 'sans-serif',
                  fontSize: '17px',
                  textTransform: 'capitalize',
                  marginRight: '2rem',
                  '&:hover': {
                    color: '#96BBD5',
                    transform: 'scale(1.05)',
                    transition: '0.3s ease-in-out'
                  }
                }}
              >
                {page.text}
              </Button>
            ))}
          </Box>

        </Toolbar>
      </Container>
    </AppBar>
  );
}
