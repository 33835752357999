
import React from 'react'
import { useOutlet } from 'react-router-dom'
import AppBarView from '../Views/AppBar/AppBar'
import { Footer } from '../Views/Footer/Footer'
import  Box  from '@mui/material/Box'
export function AppLayout() {
  
    const outlet = useOutlet()
  return (

    <Box >
      <AppBarView/>
    
      {outlet}
    
    <Footer/>  
 
 
         
    </Box>
    
  )
}
