import React, { useEffect } from "react";
import { Box, Typography, Container, Card, CardContent } from '@mui/material';
import { motion } from 'framer-motion';

export function OurServicesPage() {
    useEffect(() => {
        document.title = 'Products & Solutions | Kristal Fotonik';
    }, []);

    // Animasyon ayarları
    const cardVariants = {
        hidden: { opacity: 0, y: 20 },
        visible: { opacity: 1, y: 0 }
    };

    return (
        <Box sx={{ backgroundColor: '#f5f5f5', padding: '40px 0' }}>
            <Container maxWidth="lg">
                {/* AppBar yüksekliği kadar bir boşluk ekle */}
                <Box sx={{ height: '64px' }} />

                {/* Sayfa Başlığı */}
                <Typography variant="h2" sx={{
                    textAlign: 'center',
                    color: '#334766',
                    fontWeight: 'bold',
                    marginBottom: '40px',
                    textTransform: 'uppercase',
                    letterSpacing: '1.5px',
                }}>
                    Products & Solutions
                </Typography>

                {/* Products Kartı */}
                <motion.div
                    initial="hidden"
                    animate="visible"
                    variants={cardVariants}
                    transition={{ duration: 0.5 }}
                >
                    <Card sx={{
                        marginBottom: '40px',
                        borderRadius: '12px',
                        boxShadow: '0 4px 15px rgba(0, 0, 0, 0.1)',
                        backgroundColor: '#ffffff',
                    }}>
                        <CardContent>
                            <Typography variant="h4" sx={{
                                fontWeight: 'bold',
                                marginBottom: '20px',
                                textAlign: 'center',
                                color: '#334766',
                                textTransform: 'uppercase',
                            }}>
                                Products
                            </Typography>
                            <Typography variant="body1" sx={{ color: '#555', lineHeight: '1.8', marginBottom: '20px' }}>
                                Kristal Fotonik offers a wide range of high-performance cameras and imaging systems designed to meet the specific needs of various industries. Our products are built for precision, reliability, and scalability.
                            </Typography>
                            <Typography variant="body1" sx={{ color: '#555', lineHeight: '1.8', marginBottom: '20px' }}>
                                • High-Resolution Cameras<br />
                                Our cameras deliver exceptional image quality with high-resolution sensors, making them ideal for applications that require precise and clear imaging.
                            </Typography>
                            <Typography variant="body1" sx={{ color: '#555', lineHeight: '1.8', marginBottom: '20px' }}>
                                • AI-Powered Imaging Systems<br />
                                Leveraging cutting-edge artificial intelligence algorithms, our imaging systems enhance image clarity, color accuracy, object detection and identification.
                            </Typography>
                            <Typography variant="body1" sx={{ color: '#555', lineHeight: '1.8', marginBottom: '20px' }}>
                                • Custom Camera Modules<br />
                                We understand that each industry has unique requirements. That’s why we offer fully customizable camera modules.
                            </Typography>
                            <Typography variant="body1" sx={{ color: '#555', lineHeight: '1.8', marginBottom: '20px' }}>
                                • Compact & Ruggedized Products<br />
                                Built with robust materials, our compact and ruggedized cameras are resistant to dust, water, and shock.
                            </Typography>
                        </CardContent>
                    </Card>
                </motion.div>

                {/* Solutions Kartı */}
                <motion.div
                    initial="hidden"
                    animate="visible"
                    variants={cardVariants}
                    transition={{ duration: 0.5, delay: 0.1 }} // İkinci kartın animasyonu için gecikme
                >
                    <Card sx={{
                        marginBottom: '40px',
                        borderRadius: '12px',
                        boxShadow: '0 4px 15px rgba(0, 0, 0, 0.1)',
                        backgroundColor: '#ffffff',
                    }}>
                        <CardContent>
                            <Typography variant="h4" sx={{
                                fontWeight: 'bold',
                                marginBottom: '20px',
                                textAlign: 'center',
                                color: '#334766',
                                textTransform: 'uppercase',
                            }}>
                                Solutions
                            </Typography>
                            <Typography variant="body1" sx={{ color: '#555', lineHeight: '1.8', marginBottom: '20px' }}>
                                Imaging solutions of Kristal Fotonik span across various industries, from automotive to healthcare, and are designed to meet the most demanding requirements in challenging environments.
                            </Typography>
                            <Typography variant="body1" sx={{ color: '#555', lineHeight: '1.8', marginBottom: '20px' }}>
                                • Automotive Solutions<br />
                                Our automotive camera systems enhance vehicle safety, performance, and driver assistance.
                            </Typography>
                            <Typography variant="body1" sx={{ color: '#555', lineHeight: '1.8', marginBottom: '20px' }}>
                                • Industrial Imaging<br />
                                For the manufacturing and industrial sectors, we provide advanced imaging solutions for process monitoring, quality control, and automation.
                            </Typography>
                            <Typography variant="body1" sx={{ color: '#555', lineHeight: '1.8', marginBottom: '20px' }}>
                                • Healthcare Imaging<br />
                                In healthcare, accurate imaging is critical for diagnostics and treatment planning.
                            </Typography>
                            <Typography variant="body1" sx={{ color: '#555', lineHeight: '1.8', marginBottom: '20px' }}>
                                • Space and Aerospace Solutions<br />
                                Our rugged camera systems are built to perform in extreme conditions.
                            </Typography>
                            <Typography variant="body1" sx={{ color: '#555', lineHeight: '1.8', marginBottom: '20px' }}>
                                • Scientific Research<br />
                                Our advanced imaging systems support scientific research in fields such as biology, physics, and environmental studies.
                            </Typography>
                            <Typography variant="body1" sx={{ color: '#555', lineHeight: '1.8', marginBottom: '20px' }}>
                                • Smart Cities<br />
                                We provide imaging solutions for smart city infrastructure, from traffic monitoring to public safety systems.
                            </Typography>
                        </CardContent>
                    </Card>
                </motion.div>

                {/* Call to Action Kartı */}
                <motion.div
                    initial="hidden"
                    animate="visible"
                    variants={cardVariants}
                    transition={{ duration: 0.5, delay: 0.2 }} // Üçüncü kartın animasyonu için gecikme
                >
                    <Card sx={{
                        borderRadius: '12px',
                        boxShadow: '0 4px 15px rgba(0, 0, 0, 0.1)',
                        backgroundColor: '#ffffff',
                    }}>
                        <CardContent>
                            <Typography variant="h4" sx={{
                                fontWeight: 'bold',
                                marginBottom: '20px',
                                textAlign: 'center', // Ortalanmış
                                color: '#334766',
                                textTransform: 'uppercase',
                            }}>
                                Call to Action
                            </Typography>
                            <Typography variant="body1" sx={{
                                color: '#555',
                                lineHeight: '1.8',
                                marginBottom: '20px',
                                textAlign: 'left' // Sola hizalandı
                            }}>
                                Explore our products and solutions to see how Kristal Fotonik can help transform your imaging needs with cutting-edge technology.
                            </Typography>
                        </CardContent>
                    </Card>
                </motion.div>
            </Container>
        </Box>
    );
}
