import React, { useState, useEffect } from 'react';
import Button from '@mui/material/Button';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';


export const PageTopButton = () => {
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const scrollY = window.scrollY;
      const threshold = 300;
      setIsVisible(scrollY > threshold);
    };
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  

  const handleButtonClick = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  return (
    <Button
      variant="contained"
      
      startIcon={<ExpandLessIcon style={{ color:'#FFFFFF', marginLeft:'13px', fontSize: 35}}/>}
      onClick={handleButtonClick}
      style={{
        position: 'fixed',
        bottom: '15px',
        right: '15px',
        opacity: isVisible ? 1 : 0,
        transition: 'opacity 0.3s ease-in-out',
        borderRadius:'50%',
        width:'30px',
        height:'55px',
        backgroundColor:'#1F569D',
        color:'white',
        justifyContent:'center',
        alignItems:'center',
        marginLeft:'13px',
      }}
    ></Button>
  );
};