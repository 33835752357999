export const Logo = ({ width = '180px', height = 'auto', photoUrl = "/Logo/logo.png" }) => {
  return (
    <img
      src={photoUrl}
      alt="logo"
      style={{
        width: width,
        height: height,
        display: 'block', // Ekstra alan kaplamaması için
        margin: '0', // Boşluk kaldır
        padding: '0', // Boşluk kaldır
        border: 'none', // Kenar boşluklarını kaldır
        maxWidth: '100%', // Responsive tasarım için
      }}
    />
  );
};
