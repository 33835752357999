import React, { Suspense } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { HomePage, CorporatePage, OurServicesPage, ProjectsPage, ReferencesPage, CommunicationPage, PageNotFound } from './Pages';
import { AppLayout } from './Components';
import AppBarView from './Components/Views/AppBar/AppBar';
import { Footer } from './Components';
import './Components/Languages/index';


function Error404() {
  return (
    <div>
      <AppBarView />
      <PageNotFound />
      <Footer />
    </div>
  )
}



const App = () => {


  const megaPage = [
    { name: "/", component: <HomePage /> },
    { name: "Home", component: <HomePage /> },
    { name: "Corporate", component: <CorporatePage /> },
    { name: "OurServices", component: <OurServicesPage /> },
    { name: "Projects", component: <ProjectsPage /> },
    { name: "References", component: <ReferencesPage /> },
    { name: "Communication", component: <CommunicationPage /> },
  ]





  return (

    <Router>
      <Suspense fallback={<div />}>
        <Routes>
          <Route path="/" element={<AppLayout />}>
            {megaPage && megaPage.map((d) => (
              <Route key={d.name} path={d.name} element={d.component} />
            ))}
          </Route>
          <Route path="*" element={<Error404 />} />
        </Routes>
      </Suspense>
    </Router>

  );
};

export default App;